import React from "react";
import XMLViewer from "react-xml-viewer";

const darkTheme = {
  tagColor: "#FFD700",            // Gold color for tags
  attributeKeyColor: "#00FF00",   // Lime green for attribute keys
  attributeValueColor: "#1E90FF", // Dodger blue for attribute values
  textColor: "#FFFFFF",           // White for text
  separatorColor: "#FFA500",      // Orange for separators
  backgroundColor: "#282244",     // Dark background matching your current setup
};

const XMLViewerComponent = ({ xmlApiData }) => {
  return (
    <div
      className="xml-box-margin"
      style={{
        whiteSpace: "pre-wrap",
        color: darkTheme.textColor,
        backgroundColor: darkTheme.backgroundColor,
        padding: "10px 20px",
        borderRadius: "5px",
        maxHeight: "450px",
        overflowY: "scroll",
        margin: "10px"
      }}
    >
      {xmlApiData ? (
        typeof xmlApiData === "string" ? (
          <XMLViewer xml={xmlApiData.trim()} theme={darkTheme} collapsible />
        ) : (
          <p>XML not present at the moment.</p> // Show error message if not a string
        )
      ) : (
        <p>XML not present at the moment.</p> // Display message if xmlApiData is not available
      )}
    </div>
  );
};

export default XMLViewerComponent;