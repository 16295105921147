import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AddNewField from "./AddNewField.js";
import "./Trading.css";
// import notification from "../images/notification_Group_159.png";
// import outline from "../images/outline_Group_160.png";
import dragdrop from "../images/dragdrop_icon.png";
import settingIcon from "../images/setting-svgrepo-com.png";
import noData from "../images/No-data-cuate.png";
import checkBox from "../images/checkbox_Vector.png";
import dots_icon from "../images/dots_icon.png";
import plusIcon from "../images/plus-large-svgrepo-com.png";
// import dots_icon_selected from "../images/dots_icon_selected.png";
import axios from "axios";
import DashLoader from "./DashLoader.js";
import underline from "../images/underline_bar.png";
import success_icon from "../images/success_icon.png";
// import accentureLogo from "../images/acc_logo.png";
// import xmlFormatter from "xml-formatter";
import XMLViewerComponent from "./XMLViewerComponent";
import ReactJson from "react18-json-view";
import "react18-json-view/src/style.css";
import "react18-json-view/src/dark.css";
import DynamicTable from "./DynamicTable";
// import { Schedule } from "@mui/icons-material";

const Trading = () => {
  // const [value, setValue] = useState(0);
  const [pdfData, setPdfData] = useState(null);
  const [runIdVal, setrunIdVal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [commodityType, setCommodityType] = useState("crude");
  const [trade, setTrade] = useState("buy");
  const [templateSelection, settemplateSelection] = useState(false);
  const [templateName, setTemplateName] = useState("Default");
  const [isPDFUploaded, setIsPDFUploaded] = useState(false);
  const [tradeBotApiResponse, setTradeBotApiResponse] = useState({});
  const [tradeBotApiJsonResponse, setTradeBotApiJsonResponse] = useState({});
  const [xmlApiData, setXmlApiData] = useState({});
  const [templateList, setTemplateList] = useState([
    "Effective Date/Commencement date",
  ]);
  const [tradeTypes, settradeTypes] = useState([
    { label: "Buy", value: "buy" },
    { label: "Sell", value: "sell" },
    { label: "Transport", value: "transport" },
    { label: "Storage", value: "storage" },
  ]);
  const [templateTypes, setTemplateTypes] = useState([
    { label: "Default", value: "Default" },
  ]);
  const [showAddFieldModal, setShowAddFieldModal] = useState(false);
  const [templateHeadings, setTemplateHeadings] = useState(["Default"]);
  const [templateFields, setTemplateFields] = useState({
    "Buyer/Enterprise": {
      long_desc:
        "Shell Trading (US) Company, a Delaware corporation(Party Name will be usually words like Company,LTD. Etc and will marked in Bold letters usually)",
      short_desc:
        "Party name, usually a corporation, e.g., Shell Trading (US) Company.",
    },
  });
  const commodityTypes = [
    { label: "Crude", value: "crude" },
    { label: "Natural Gas", value: "natural_gas" },
  ];
  const [addTemplateFields, setAddTemplateFields] = useState(templateFields);
  const [mappedTemplates, setMappedTemplates] = useState({
    [templateHeadings]: templateFields,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [count, setCount] = useState(1);
  // const [tabValue, setTabValue] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [uploadedPDFFile, setUploadedPDFFile] = useState();
  const [uploadedPDFFileDummy, setUploadedPDFFileDummy] = useState();
  const [isValChanged, setIsValChanged] = useState(false);
  const navigate = useNavigate();
  const [excuetionStatus, setExcuetionStatus] = useState();
  const [waitForAPI, setWaitForAPI] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const pollingRef = useRef(false);
  const [fileSize, setFileSize] = useState("");

  const fetchPDF = async () => {
    try {
      // Fetch the PDF as a blob
      const response = await fetch(
        "https://industrygenai.accenture.com/tradingdealbookingprod/get_current_document"
      );
      const blob = await response.blob();

      // Use FileReader to convert the blob to base64 data URL
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedPDFFileDummy(reader.result); // Set the base64 result to state
      };

      reader.readAsDataURL(blob); // Read the blob as a base64 data URL
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  const uploadFileForTradeBot = async () => {
    setIsProcessing(true);
    setWaitForAPI(true);
    setIsLoading(true);
    pollingRef.current = true;

    const formData = new FormData();

    formData.append("file_input", uploadedPDFFile);
    const jsonData = {
      commodity_type: commodityType,
      trade_type: trade,
      template_details: templateName,
      run_id: "run_test_" + runIdVal,
    };
    const blob = new Blob([JSON.stringify(jsonData)], {
      type: "application/json",
    });
    formData.append("json_input", blob, "data.json");

    setExcuetionStatus({
      "Data Upload": "In Progress",
    });
    const intervalId = setInterval(() => {
      if (pollingRef.current) {
        getExceutionStatus(runIdVal, intervalId);
      }
    }, 4000);
    await axios
      .post(
        "https://industrygenai.accenture.com/tradingdealbookingprod/trading_bot",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        fetchPDF();
        setTradeBotApiJsonResponse(
          response?.data?.response?.json_output
            ? {
                TranfFields:
                  response.data.response.json_output.tranfFields || [],
                Schedules: response.data.response.json_output.schedules || [],
              }
            : {}
        );
        setTradeBotApiResponse(
          response?.data?.response?.json_output
            ? {
                TranfFields: response.data.response.json_output.tranfFields.map(
                  (field) => ({
                    ...field,
                    page_nums: field.page_nums?.map((num) => num + 1), 
                    ...(field.page_nums !== undefined && {
                      show_in_pdf: "Link source",
                    }),
                  })
                ),
                Schedules: response.data.response.json_output.schedules || [],
              }
            : {}
        );
        setXmlApiData(response?.data?.response?.xml_output);
        console.log("success!", tradeBotApiResponse);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false); // End loading
      });
  };

  const stopProcessing = () => {
    pollingRef.current = false;
    setIsProcessing(false);
    setWaitForAPI(false);
    setIsLoading(false);
    setTradeBotApiJsonResponse({});
    setTradeBotApiResponse({});
    setExcuetionStatus();
  };

  const onRegenerate = () => {
    const clearCache = async () => {
      try {
        await fetch("https://industrygenai.accenture.com/tradingdealbookingprod/clear_llm_cache");
    
        console.log("Cache clear request sent.");
      } catch (error) {
        console.error("Error clearing cache:", error);
      }
    };
    
    // Call the function when needed
    clearCache();
    stopProcessing();
  }

  const getExceutionStatus = async (run_id, intervalId) => {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        run_id: "run_test_" + run_id,
      }),
    };

    try {
      const response = await fetch(
        "https://industrygenai.accenture.com/tradingdealbookingprod/get_current_status",
        options
      );

      if (!response.ok) {
        throw new Error(
          `API error: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();

      if (data) {
        console.log("data", data);
        setExcuetionStatus(data?.response);

        const allCompleted = Object.values(data?.response).every(
          (status) => status === "Completed"
        );

        // Stop polling if all tasks are completed
        if (allCompleted) {
          setWaitForAPI(false);
          pollingRef.current = false;
          clearInterval(intervalId);
          setIsPDFUploaded(false);
          setUploadedPDFFile();
          setIsProcessing(false);
        }
      } else {
        console.log("No data received from API");
        clearInterval(intervalId); // Stop polling on no data
      }
    } catch (error) {
      console.error("Error in API call:", error.message);
      setWaitForAPI(false);
      clearInterval(intervalId); // Stop polling on error
    }
  };

  const Loading = () => {
    return (
      <div className="loader-bplam" style={{ marginTop: "100px" }}>
        <div
          className="dot-cq-bplam red-c-bplam"
          style={{ backgroundColor: "#5858FF" }}
        ></div>
        <div
          className="dot-cq-bplam green-c-bplam"
          style={{ backgroundColor: "#00ADEB" }}
        ></div>
        <div
          className="dot-cq-bplam blue-c-bplam"
          style={{ backgroundColor: "#00F2C2" }}
        ></div>
      </div>
    );
  };

  const handleDropdownChange = (event, name) => {
    const selectedValue = event.target.value;
    if (name === "commodity") setCommodityType(selectedValue);
    else setTrade(selectedValue);
    setIsValChanged(true);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const fileExtension = file?.name.split(".").pop().toLowerCase();

    if (fileExtension === "pdf") {
      const reader = new FileReader();
      reader.onload = () => {
        setPdfData(reader.result); // Set the base64 result to state
      };

      reader.readAsDataURL(file);

      reader.onerror = () => {
        console.error("Error reading file");
      };
      setIsPDFUploaded(true);
      setUploadedPDFFile(file);
      //setUploadedPDFFileDummy(URL.createObjectURL(file));
      setIsProcessing(false);
      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2) + " MB";
      setFileSize(sizeInMB);
      let run_id = Math.floor(1000 + Math.random() * 9000);
      setrunIdVal(run_id);
    } else {
      alert("Please upload a PDF file.");
    }
  };

  const handleTemplates = () => {
    if (!isPDFUploaded) {
      alert("Please upload a PDF file first.");
      return; // Exit the function if the PDF is not uploaded
    }

    let options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        commodity_type: commodityType,
        trade_type: trade,
      }),
    };

    fetch(
      "https://industrygenai.accenture.com/tradingdealbookingprod/get_template_details",
      options
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setTemplateFields(data.response);
          setAddTemplateFields(data.response);
          setMappedTemplates(data.response);
          if (isValChanged) settemplateSelection(true);
          else settemplateSelection(!templateSelection);
        } else {
          console.log("Error in API");
        }
      });
    setIsValChanged(false);
  };

  const saveCustomTemplate = async () => {
    setIsLoading(true);
    pollingRef.current = true;
    settemplateSelection(!templateSelection);
    let value = `custom ${count} - ${commodityType}`;
    const formData = new FormData();
    formData.append("file_input", uploadedPDFFile);
    let run_id = runIdVal ? "run_test_" + runIdVal : "";
    const jsonData = {
      commodity_type: commodityType,
      trade_type: value,
      template_details: addTemplateFields,
      run_id: run_id,
    };
    const blob = new Blob([JSON.stringify(jsonData)], {
      type: "application/json",
    });
    formData.append("json_input", blob, "data.json");

    setExcuetionStatus({
      "Data Upload": "In Progress",
    });
    const intervalId = setInterval(() => {
      if (pollingRef.current) {
        getExceutionStatus(runIdVal, intervalId);
      }
    }, 4000);

    await axios
      .post(
        "https://industrygenai.accenture.com/tradingdealbookingprod/trading_bot",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        if (response) {
          console.log("Save API Called", response);
          setTradeBotApiJsonResponse(
            response?.data?.response?.json_output
              ? {
                  TranfFields:
                    response.data.response.json_output.tranfFields || [],
                  Schedules: response.data.response.json_output.schedules || [],
                }
              : {}
          );
          setTradeBotApiResponse(
            response?.data?.response?.json_output
              ? {
                  TranfFields:
                    response.data.response.json_output.tranfFields.map(
                      (field) => ({
                        ...field,
                        page_nums: field.page_nums?.map((num) => num + 1), 
                        ...(field.page_num !== undefined && {
                          show_in_pdf: "Link source",
                        }),
                      })
                    ),
                  Schedules: response.data.response.json_output.schedules || [],
                }
              : {}
          );
          if (response?.data?.response?.xml_output) {
            setXmlApiData(response?.data?.response?.xml_output);
          } else {
            setXmlApiData("");
          }
          console.log("success!", tradeBotApiResponse);
          // const updatedTemp = {
          //   ...mappedTemplates,
          //   [value]: addTemplateFields,
          // };
          // setMappedTemplates(updatedTemp);

          // setCount(count + 1);
          setIsEditing(false);
          setIsLoading(false);
          //settemplateSelection(!templateSelection)
        } else {
          console.log("Error in API");
          setIsLoading(false);
          //settemplateSelection(!templateSelection)
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        //settemplateSelection(!templateSelection)
      });
  };

  const handleTemplateList = (value) => {
    if (templateList.includes(value)) {
      setTemplateList(templateList.filter((item) => item !== value));
    } else {
      setTemplateList([...templateList, value]);
    }
  };

  const onFormSubmit = (values) => {
    let count_val = Math.floor(1000 + Math.random() * 9000);
    setCount(count_val);
    let templateNewName = `custom ${count_val} - ${commodityType}`;
    setTemplateHeadings((oldArray) => [...oldArray, templateNewName]);
    const newTemplate = { label: templateNewName, value: templateNewName };
    if (!templateTypes.some((template) => template.value === templateNewName)) {
      // Update state with the new template type
      setTemplateTypes((prevTemplateTypes) => [
        ...prevTemplateTypes,
        newTemplate,
      ]);
    }

    let updatedTempObj;
    Object.entries(values).map(([key, value]) => {
      updatedTempObj = {
        ...addTemplateFields,
        [key]: { long_desc: "", short_desc: value },
      };
    });
    setAddTemplateFields(updatedTempObj);

    setShowAddFieldModal(false);
    setTemplateName(templateNewName);
  };

  const handleAddButton = () => {
    setShowAddFieldModal(true);
    setIsEditing(true);
  };

  const handleOnClose = () => {
    setShowAddFieldModal(false);
    setIsEditing(true);
  };

  const removeFile = () => {
    setIsPDFUploaded(false);
    setFileSize("");
  };

  const renderTemplateSection = (key, value) => {
    console.log(templateList);
    if (key === "Structured Output" && value?.short_desc === "Completed") {
      setWaitForAPI(false); // Set waitForAPI to false
    }

    return (
      <>
        <div
          className={`flex-item ${
            templateList.includes(key) && "borderType-selected"
          }`}
          onClick={() => handleTemplateList(key)}
        >
          <div style={{ margin: "0px 10px", display: "flex" }}>
            <div>
              <img src={dots_icon} style={{ marginRight: "10px" }} />
            </div>
            <div>
              <div className="Placeholder-3">{key}</div>
              <div className="Placeholder-4">{value?.short_desc}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderLoader = () => {
    return (
      <>
        {excuetionStatus &&
          Object.keys(excuetionStatus).map((key, value) => (
            <>
              {excuetionStatus[key] == "Completed" ? (
                <>
                  <div className="loader-block loader-box">
                    <div style={{ display: "flex" }}>
                      <div style={{ margin: "0px 25px 5px 15px" }}>
                        <img src={success_icon} />
                      </div>
                      <div>
                        <div className="Placeholder-3">{key}</div>
                        <div className="Placeholder-4">
                          {excuetionStatus[key]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px" }} />
                </>
              ) : (
                <>
                  <div className="loader-block loader-box">
                    <div style={{ display: "flex" }}>
                      <div style={{ margin: "0px 25px 5px 15px" }}>
                        <DashLoader />
                      </div>
                      <div>
                        <div className="Placeholder-3">{key}</div>
                        <div className="Placeholder-4">
                          {excuetionStatus[key]}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ))}
      </>
    );
  };

  const handlePrevNav = (event) => {
    navigate("/");
  };

  return (
    <div className="trading-external">
      <div className="main-container-trading">
        <div style={{ width: "100%" }}>
          <div className="Heading-v">
            <span
              onClick={handlePrevNav}
              style={{ color: "#AD8BFF", cursor: "pointer" }}
            >
              Oil & Gas{" "}
            </span>{" "}
            / Gen AI Trading
          </div>
          <div style={{ display: "flex" }}>
            {/* <img
              src={accentureLogo}
              style={{ cursor: "pointer", margin: "10px" }}
            /> */}
            <p className="heading heading-genai-trading">
              Gen AI Trading Contract Assistant{" "}
            </p>
          </div>

          <div className="dropdown-container">
            <div style={{ display: "flex" }}>
              <p className="dropdown-heading">Commodity Type </p>
              <select
                value={commodityType}
                className="trading-dropdowns"
                onChange={(e) => handleDropdownChange(e, "commodity")}
              >
                <option disabled selected style={{ display: "none" }}>
                  Select the Commodity Type
                </option>
                {commodityTypes.map((list) => (
                  <option value={list.value}>{list.label}</option>
                ))}
              </select>
            </div>
            <div style={{ display: "flex", marginLeft: "auto" }}>
              <p className="dropdown-heading"> Trade Type </p>
              <select
                value={trade}
                className="trading-dropdowns template-dropdown"
                onChange={(e) => handleDropdownChange(e, "trade")}
              >
                <option disabled selected style={{ display: "none" }}>
                  Select the Trade type
                </option>
                {tradeTypes.map((list) => (
                  <option value={list.value}>{list.label}</option>
                ))}
              </select>
              <p className="dropdown-heading"> Template </p>
              <select
                value={trade}
                className="template-dropdown filter-icon-setting"
                onChange={(e) => handleDropdownChange(e, "template")}
              >
                <option disabled selected style={{ display: "none" }}>
                  Select the Template
                </option>
                {templateTypes.map((list) => (
                  <option value={list.value}>{list.label}</option>
                ))}
              </select>
              <button
                className="setting-button"
                // disabled={isValChanged}
                onClick={handleTemplates}
              >
                <img src={settingIcon} />
              </button>
            </div>

            {/* <button
              className={`settingIcon icon-text ${
                !isValChanged && "icon-disabled"
              }`}
              onClick={handleTemplates}
              disabled={!isValChanged}
            > */}
            {/* <img src={notification} style={{ cursor: "pointer" }} /> */}
            {/* Go */}
            {/* </button> */}
            {/* <span>
              <img src={outline} style={{ cursor: "pointer" }} />
            </span> */}
          </div>
          {/* <div className="line-height" /> */}
          <div className="line-section-major" />
          <div className="dropdown-container-2">
            <div className="mainbox1">
              <div class="box1">
                {templateSelection ? (
                  <div className="divSection">
                    <p className="sectionHeading sectionHeading-first">
                      Templates
                    </p>
                    {templateHeadings.map((heading) => (
                      <div
                        className={`templateHeading ${
                          templateName === heading && "borderType-selected"
                        }`}
                        onClick={() => setTemplateName(heading)}
                      >
                        <div style={{ margin: "0px 10px" }}>
                          <img
                            src={checkBox}
                            className={`displayProperty ${
                              templateName !== heading && "hideInputBox"
                            }`}
                          />
                          <span>{heading}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="browse-files-containers">
                    {/* <div className="placeholders">
                      <div>
                        <img className="browse-logo" src={dragdrop} />
                      </div>
                      {isPDFUploaded ? (
                        <div className="Placeholder1">File is uploaded</div>
                      ) : (
                        <div className="Placeholder1">
                          Choose a file or drag & drop it here
                        </div>
                      )}
                      <div className="Placeholder2">
                        Upload in PDF, Word formats up to 10 MB
                      </div>
                    </div> */}
                    <div className="upload-button-section">
                      {!isPDFUploaded ? (
                        <>
                          <label
                            htmlFor="imageUpload"
                            className="upload-button"
                          >
                            <p className="upload-btn-label">Browse File</p>
                          </label>
                          <input
                            className="file-class-c"
                            type="file"
                            id="imageUpload"
                            accept=".pdf"
                            onChange={handleFileUpload}
                            hidden
                          />
                        </>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <button
                            onClick={uploadFileForTradeBot}
                            className="upload-button"
                            disabled={isProcessing}
                          >
                            <p className="upload-btn-label">Process</p>
                          </button>
                          {isProcessing && (
                            <button
                              onClick={stopProcessing}
                              className="stop-button"
                              disabled={!isProcessing}
                            >
                              <p className="upload-btn-label">Stop</p>
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    {!isPDFUploaded || isProcessing ? (
                      <>
                        <div className="placeholders">
                          <div className="Placeholder2"> or </div>
                          <div className="Placeholder1">
                            Drag and drop a file here
                          </div>
                          <div className="Placeholder2">
                            (File size must be below 5MB)
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="file-details">
                        <span>
                          {uploadedPDFFile.name} ({fileSize})
                        </span>
                        <button
                          className="remove-file-button"
                          onClick={removeFile}
                        >
                          {" "}
                          <span>x</span>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {!templateSelection && (
                <div className="box1 sub-box-1">
                  <div style={{ margin: "10px" }}>
                    <div className="Placeholder1 status-placeholder">
                      Status
                    </div>
                    <div>
                      <img src={underline} style={{ width: "100%" }} />
                    </div>
                    {renderLoader()}
                  </div>
                </div>
              )}
            </div>
            <div class="box2">
              {templateSelection ? (
                <div className="divSection">
                  <div style={{ margin: "10px" }}>
                    <span className="sectionHeading">
                      Template : {templateName}
                    </span>
                    <button className="add-button" onClick={handleAddButton}>
                      <img className="add-icon" src={plusIcon} />
                      Add
                    </button>
                  </div>{" "}
                  <div className="flex-container">
                    {/* DJ:: TODO- check why this is not working */}
                    {/* {isEditing
                    ? Object.entries(addTemplateFields).map(([key, value]) =>
                        renderTemplateSection(key, value)
                      )
                    : Object.entries(mappedTemplates).map(
                        ([key, value]) =>
                          key === templateName &&
                          Object.entries(value).map(([key, value]) =>
                            renderTemplateSection(key, value)
                          )
                      )}
                   */}

                    {isEditing
                      ? Object.entries(addTemplateFields).map(
                          ([key, value]) => (
                            <div
                              className={`flex-item ${
                                templateList.includes(key) &&
                                "borderType-selected"
                              }`}
                              onClick={() => handleTemplateList(key)}
                            >
                              <div
                                style={{ margin: "0px 10px", display: "flex" }}
                              >
                                <div>
                                  <img
                                    src={dots_icon}
                                    style={{ marginRight: "10px" }}
                                  />
                                </div>

                                <div>
                                  <div className="Placeholder-3">{key}</div>
                                  <div className="Placeholder-4">
                                    {value.short_desc}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      : Object.entries(mappedTemplates).map(([key, value]) => (
                          <div
                            className={`flex-item ${
                              templateList.includes(key) &&
                              "borderType-selected"
                            }`}
                            onClick={() => handleTemplateList(key)}
                          >
                            <div
                              style={{ margin: "0px 10px", display: "flex" }}
                            >
                              <div>
                                <img
                                  src={dots_icon}
                                  style={{ marginRight: "10px" }}
                                />
                              </div>

                              <div>
                                <div className="Placeholder-3">{key}</div>
                                <div className="Placeholder-4">
                                  {value.short_desc}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                  {templateSelection && (
                    <>
                      <button
                        className="save-cancel-button save-button"
                        onClick={() => saveCustomTemplate()}
                      >
                        Save
                      </button>

                      <button
                        className="save-cancel-button cancel-button"
                        onClick={() => settemplateSelection(!templateSelection)}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              ) : tradeBotApiResponse ? (
                <>
                  <div className="trading-tab" style={{ marginLeft: "10px" }}>
                    <div>
                      <button
                        onClick={() => setActiveTab(0)}
                        className={
                          activeTab === 0 ? "active-tab-button" : "tab-button"
                        }
                      >
                        JSON
                      </button>
                      <button
                        onClick={() => setActiveTab(1)}
                        className={
                          activeTab === 1 ? "active-tab-button" : "tab-button"
                        }
                      >
                        CSV
                      </button>
                      <button
                        onClick={() => setActiveTab(2)}
                        className={
                          activeTab === 2 ? "active-tab-button" : "tab-button"
                        }
                      >
                        XML
                      </button>
                    </div>
                    <div class="left-button-allign">
                      <button
                        onClick={onRegenerate}
                        className="regenrate-trading-button"
                        disabled={isProcessing}
                      >
                        <p className="regenrate-button-label">Regenerate</p>
                      </button>
                    </div>
                  </div>
                  <div className="tab-content">
                    {isLoading ? (
                      // Show the loading animation while loading
                      <Loading />
                    ) : (
                      <>
                        {activeTab === 0 && (
                          <div className="json-box-margin">
                            <ReactJson
                              src={tradeBotApiJsonResponse}
                              theme="a11y"
                              dark={true}
                            />
                          </div>
                        )}
                        {activeTab === 1 && (
                          <div
                            className="json-box-margin"
                            style={{ padding: "20px" }}
                          >
                            {/* Check if data is available */}
                            {tradeBotApiResponse &&
                            tradeBotApiResponse.TranfFields &&
                            Array.isArray(tradeBotApiResponse.TranfFields) ? (
                              <DynamicTable
                                data={tradeBotApiResponse}
                                pdfData={pdfData}
                              />
                            ) : (
                              <p>CSV not present at the moment.</p>
                            )}
                          </div>
                        )}

                        {activeTab === 2 && (
                          <div style={{ margin: "10px" }}>
                            <XMLViewerComponent xmlApiData={xmlApiData} />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <img src={noData} style={{ margin: "9% 32%" }} />
              )}
            </div>
          </div>

          {showAddFieldModal && (
            <AddNewField onClose={handleOnClose} onSubmit={onFormSubmit} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Trading;
