import React, { props,useState } from 'react';
import drilling from './images/drilling.svg';
import scrm from './images/scrm.svg';
import contract from './images/Contract.png';
import fieldworker from './images/f1.png';
import esg from './images/esg.png';
import marketWatcher from './images/collage-finance-banner-concept_23-2150608840.jpg';
import fieldFodge from './images/fieldforge.jpg';
import saleslead from './images/saleslead.png';
import retail from './images/retail.jpg';
import knowledge from './images/person-using-ai-tool-job_23-2150711481.jpg';
import corrosion from './images/corrosion.svg';
import asset from './images/asset.svg';
import sustain from './images/sustainability.svg';
import RefineryInspection from './images/RefineryInspection.svg';
import timeseriesIcon from './images/timeseries_icon.png';
import scm from './images/scm.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faChartBar, faSearch, faTools, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import Link from React Router
import './App.css';
import optimboard from './images/optim-board.svg';
import trading from './images/tradingImg.png';
import contract_authoring from './images/contract_authoring.jpg';
import contract_risk_Assessment from './images/contract_risk_Assessment.jpg';

const App = (props) => {
  const [showDemo, setShowDemo] = useState(false);
  return (
    <div className='energy-m'>
      <div className='sub-H'>
        <div onClick={() => {setShowDemo(false)}} className = {showDemo ? 'head' : 'head1'}>Home</div>
        <div className='head'>Planning & Strategy</div>
        <div className='head'>Core Operations</div>
        <div className='head'>Financial Management</div>
        <div className='head'>Supply Chain</div>
        {/* <div className='head'>ESG</div> */}
        <div className='head'>Enterprise Functions</div>
        <div onClick={() => {setShowDemo(true)}} style={{cursor:"pointer"}} className = {showDemo ? 'head1' : 'head'}>Demo</div>
      </div >
      {!showDemo ?
        <>
      <div className='icons-m'>

        <Link to='/CorrosionPage' className='icon-container-m' onClick={props.closeSideBar}>
          <img className='img-class-m' src={corrosion} alt="primary header" /> <p className='landing-text'>Corrosion Detection</p>
        </Link>
        <Link to='/Sustainability' className='icon-container-m' onClick={props.closeSideBar}>
          <img className='img-class-m' src={sustain} alt="primary header" /><p className='landing-text'> Sustainability Benchmarking</p>
        </Link>
        <Link to='/Apm' className='icon-container-m' onClick={props.closeSideBar}>
          <img className='img-class-m' src={asset} alt="primary header" /> <p className='landing-text'>Asset Performance Management</p>
        </Link>
        <Link to='/OsduSearch' className='icon-container-m' onClick={props.closeSideBar}>
          <img className='img-class-m' src={scm} alt="primary header" /> <p className='landing-text'>OSDU Search Assistant</p>
        </Link>

      </div>
      <div className=' icons-m'>
      <Link to='/Scrm' className='icon-container1-m'>
        <img className='img-class-m' src={scrm} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>Supply Chain Risk Management</p>
      </Link>
      <Link to='/Drilling' className='icon-container1-m'>
        <img className='img-class-m' src={drilling} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>Drilling Operations Assistant</p>
      </Link>
      
      <Link to='/RefineryInspectionBot' className='icon-container1-m'>
        <img className='img-class-m' src={RefineryInspection} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>Refinery Inspection Assistance</p>
      </Link>
        <Link to='/ESGDomainModel' className='icon-container1-m'>
        <img className='img-class-m' src={knowledge} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>ESG Domain Model</p>
      </Link>
       
      </div>
      <div className=' icons-m'>
        <Link to='/contract' className='icon-container1-m' onClick={props.closeSideBar}>
        <img  className='img-class-m2' src={contract}  alt="primary header" /> <p className='landing-text'>Contract and Financial Insight Assistant</p>
        </Link>
        <Link to='/saleslead' className='icon-container1-m' onClick={props.closeSideBar}>
        <img  className='img-class-m2' src={saleslead}  alt="primary header" /> <p className='landing-text'>Sales Lead Assistant </p>
        </Link>
        <Link to='/FieldCompanion' className='icon-container1-m' onClick={props.closeSideBar}>
        <img  className='img-class-m2' src={fieldworker}  alt="primary header" /> <p className='landing-text'>Field Worker Companion</p>
        </Link>
        <Link to='/FieldForge' className='icon-container1-m' onClick={props.closeSideBar}>
          <img className='img-class-m' src={fieldFodge} alt="primary header" /> <p className='landing-text'>Field Development Optimization</p>
        </Link>
      </div>
      <div className='icons-m'>

       
        <Link to='/esg' className='icon-container1-m' onClick={props.closeSideBar}>
          <img className='img-class-m' src={esg} alt="primary header" /> <p className='landing-text'>ESG compliance and reporting</p>
        </Link>
        <Link to='/MarketWatcher' className='icon-container1-m' onClick={props.closeSideBar}>
          <img className='img-class-m' src={marketWatcher} alt="primary header" /> <p className='landing-text'>Market Watcher</p>
        </Link>
        <Link to='http://20.54.226.107/' className='icon-container1-m'>
        <img className='img-class-m' src={knowledge} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>Knowledge Management Assistant</p>
      </Link>
        <Link to='http://20.56.202.53/frai' className='icon-container1-m'>
          <img className='img-class-m2' src={retail} alt="primary header" onClick={props.closeSideBar} />  <p className='landing-text'>Fuel Retail Analytics</p>
        </Link>

      </div>
      <div className='icons-m'>
       <Link to='/Optimization' className='icon-container1-m'>
        <img style={{height:'unset'}} className='img-class-m' src={optimboard} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>OptiGenius</p>
      </Link> 
      <Link to='http://10.0.0.4:3000/Timeseries' className='icon-container1-m'>
        <img className='img-class-m2' src={timeseriesIcon} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>Timeseries Forecasting</p>
      </Link>
        <Link to='/trading' className='icon-container1-m'>
        <img className='img-class-m' src={sustain} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>Trading Contract Assistant</p>
      </Link>
        <Link to='http://108.141.153.160/' className='icon-container1-m'>
        <img className='img-class-m' src={trading} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>Trade Knowledge Miner</p>
      </Link>
      </div>     

      </> : <>
      <div className='icons-m'>

<Link to='/CorrosionDemo' className='icon-container-m' onClick={props.closeSideBar}>
  <img className='img-class-m' src={corrosion} alt="primary header" /> <p className='landing-text'>Corrosion Detection</p>
</Link>
<Link to='/SustainabilityDemo' className='icon-container-m' onClick={props.closeSideBar}>
  <img className='img-class-m' src={sustain} alt="primary header" /><p className='landing-text'> Sustainability Benchmarking</p>
</Link>
<Link to='/ApmDemo' className='icon-container-m' onClick={props.closeSideBar}>
  <img className='img-class-m' src={asset} alt="primary header" /> <p className='landing-text'>Asset Performance Management</p>
</Link>
<Link to='/OptimizationDemo' className='icon-container-m'>
        <img className='img-class-m' src={optimboard} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>Optimization</p>
      </Link>
      


</div>

<div className=' icons-m'>
        <Link to='/contract' className='icon-container1-m' onClick={props.closeSideBar}>
        <img  className='img-class-m2' src={contract}  alt="primary header" /> <p className='landing-text'>Contract and Financial Insight Assistant</p>
        </Link>
        <Link to='/saleslead' className='icon-container1-m' onClick={props.closeSideBar}>
        <img  className='img-class-m2' src={saleslead}  alt="primary header" /> <p className='landing-text'>Sales Lead Assistant </p>
        </Link>
        <Link to='/FieldCompanion' className='icon-container1-m' onClick={props.closeSideBar}>
        <img  className='img-class-m2' src={fieldworker}  alt="primary header" /> <p className='landing-text'>Field Worker Companion</p>
        </Link>
        <Link to='/FieldForge' className='icon-container1-m' onClick={props.closeSideBar}>
          <img className='img-class-m' src={fieldFodge} alt="primary header" /> <p className='landing-text'>Field Development Optimization</p>
        </Link>
      </div>
      <div className='icons-m'>

       
        <Link to='/esg' className='icon-container1-m' onClick={props.closeSideBar}>
          <img className='img-class-m' src={esg} alt="primary header" /> <p className='landing-text'>ESG compliance and reporting</p>
        </Link>
        <Link to='/MarketWatcher' className='icon-container1-m' onClick={props.closeSideBar}>
          <img className='img-class-m' src={marketWatcher} alt="primary header" /> <p className='landing-text'>Market Watcher</p>
        </Link>
        
        <Link to='http://20.56.202.53/frai' className='icon-container1-m'>
          <img className='img-class-m2' src={retail} alt="primary header" onClick={props.closeSideBar} />  <p className='landing-text'>Fuel Retail Analytics</p>
        </Link>
        <Link to='/RefineryInspectionBotDemo' className='icon-container1-m'>
          <img className='img-class-m' src={RefineryInspection} alt="primary header" onClick={props.closeSideBar} />  <p className='landing-text-drill'>Refinery Inspection Assistance</p>
        </Link>
      </div>   

      <div  className='icons-m'>
      <Link to='/supplychainTowerDemo' className='icon-container-m'>
        <img className='img-class-m' src={optimboard} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>Supplychain Tower</p>
      </Link>
      <Link to='/contractAuthoring' className='icon-container-m'>
        <img className='img-class-m' src={contract_authoring} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>Contract Authoring</p>
      </Link>
      <Link to='/contractRiskAssessment' className='icon-container-m'>
        <img className='img-class-m' src={contract_risk_Assessment} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>Contract Risk Assessment</p>
      </Link>
        </div>  
     
</>}
    </div>
  );
};

export default App;
