import React, { props } from 'react';
import scrm from './images/scrm.svg';
import { Link } from 'react-router-dom';
import './App.css';
import BPLAM from './images/formulationsss.svg';
import Quaimg from './images/Qualisght.jpg';
import Optiimg from './images/Optimus.jpg';

const isMainScreen = true;
const App = (props) => {
  // Energy.js

  // Other code for Energy.js

  return (
    <div className='energy-m'>
      <div className='sub-H'>
        <div className='head1'>Home</div>
        <div className='head'>Planning & Strategy</div>
        <div className='head'>Core Operations</div>
        <div className='head'>Financial Management</div>
        <div className='head'>Supply Chain</div>
        <div className='head'>ESG</div>
        <div className='head'>Enterprise Functions</div>
      </div >
      <div className=' icons-m'>
        <Link to='http://108.141.208.199/formulation' className='icon-container1-m'>
          <img className='img-class-m2' src={BPLAM} alt="primary header" onClick={props.closeSideBar} />  <p className='landing-text'>PRISE</p>
        </Link>
        <Link to='http://108.141.125.106/Scrm' className='icon-container1-m'>
          <img className='img-class-m2' src={scrm} alt="primary header" onClick={props.closeSideBar} />  <p className='landing-text'>STRIDE</p>
        </Link>
        <Link to='http://108.141.177.228/lifescience' className='icon-container1-m'>
          <img className='img-class-m2' src={Quaimg} alt="primary header" onClick={props.closeSideBar} />  <p className='landing-text'>Qualisght</p>
        </Link>
        <Link to='http://98.64.21.18/fieldservice' className='icon-container1-m'>
          <img className='img-class-m2' src={Optiimg} alt="primary header" onClick={props.closeSideBar} />  <p className='landing-text'>Optimus</p>
        </Link>
      </div>

    </div>
  );
};

export default App;
